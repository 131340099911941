.button {
    background-color: yellowgreen; /* Stan red */
    border: 20px;
    text-align: left;
    display: inline-block;
    font-size: 16px;
    margin: 15px 8px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: grey;
  }